<template>
  <div
    ref="echart"
    v-resize="onResize"
    style="width: inherit; height: inherit"
  />
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import { option } from './lib/transformer';

export default {
  components: {

  },
  props: [],
  data() {
    return {
      chart: null,
    };
  },
  computed: {

  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {

    },
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};

</script>
